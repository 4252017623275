import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISelect } from '@tecalisdev/infinity';
import { Observable } from 'rxjs';

const PROVINCIAS: ISelect[] = [
	{ id: 1, label: "Álava" },
	{ id: 2, label: "Albacete" },
	{ id: 3, label: "Alicante" },
	{ id: 4, label: "Almería" },
	{ id: 5, label: "Asturias" },
	{ id: 6, label: "Ávila" },
	{ id: 7, label: "Badajoz" },
	{ id: 8, label: "Barcelona" },
	{ id: 9, label: "Burgos" },
	{ id: 10, label: "Cáceres" },
	{ id: 11, label: "Cádiz" },
	{ id: 12, label: "Cantabria" },
	{ id: 13, label: "Castellón" },
	{ id: 14, label: "Ciudad Real" },
	{ id: 15, label: "Córdoba" },
	{ id: 16, label: "Cuenca" },
	{ id: 17, label: "Girona" },
	{ id: 18, label: "Granada" },
	{ id: 19, label: "Guadalajara" },
	{ id: 20, label: "Guipúzcoa" },
	{ id: 21, label: "Huelva" },
	{ id: 22, label: "Huesca" },
	{ id: 23, label: "Illes Balears" },
	{ id: 24, label: "Jaén" },
	{ id: 25, label: "La Coruña" },
	{ id: 26, label: "La Rioja" },
	{ id: 27, label: "Las Palmas" },
	{ id: 28, label: "León" },
	{ id: 29, label: "Lleida" },
	{ id: 30, label: "Lugo" },
	{ id: 31, label: "Madrid" },
	{ id: 32, label: "Málaga" },
	{ id: 33, label: "Murcia" },
	{ id: 34, label: "Navarra" },
	{ id: 35, label: "Ourense" },
	{ id: 36, label: "Palencia" },
	{ id: 37, label: "Pontevedra" },
	{ id: 38, label: "Salamanca" },
	{ id: 39, label: "Santa Cruz de Tenerife" },
	{ id: 40, label: "Segovia" },
	{ id: 41, label: "Sevilla" },
	{ id: 42, label: "Soria" },
	{ id: 43, label: "Tarragona" },
	{ id: 44, label: "Teruel" },
	{ id: 45, label: "Toledo" },
	{ id: 46, label: "Valencia" },
	{ id: 47, label: "Valladolid" },
	{ id: 48, label: "Vizcaya" },
	{ id: 49, label: "Zamora" },
	{ id: 50, label: "Zaragoza" },
	{ id: 51, label: "Ceuta" },
	{ id: 52, label: "Melilla" }
  ];

@Injectable({
	providedIn: 'root',
})
export class PaisesService {
	constructor(private http: HttpClient) {}

	getPaises(): Observable<any> {
    return this.http.get<any>('https://restcountries.com/v3.1/all?fields=name,cca2&lang=es');
  }

  getProvincias(): ISelect[] {
	return PROVINCIAS;
  }
}
