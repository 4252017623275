import { Injectable } from '@angular/core';
import { ITableHeader } from '@tecalisdev/infinity';

@Injectable({
	providedIn: 'root',
})
export class TableService {
	constructor() {}

	getHeaders(type: string): ITableHeader[] {
		if (type === 'mancomunados')
			return [
				{
					label: 'NOMBRE Y APELLIDOS',
				},
				{
					label: 'DNI',
				},
				{
					label: 'EMAIL',
				},
				{
					label: 'TELEFONO',
				},
				{
					label: '',
				},
			];
		if (type === 'shops')
			return [
				{
					label: 'NOMBRE',
				},
				{
					label: 'EMAIL',
				},
				{
					label: 'DIRECCIÓN',
				},
				{
					label: 'POBLACIÓN',
				},
				{
					label: 'PROVINCIA',
				},
				{
					label: 'CÓDIGO POSTAL',
				},
				{
					label: '',
				},
			];
		return [];
	}
}
