import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { LanguageService } from './core/translate/language.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	languageOptions: any[] = [
		{ label: 'ES', id: 'es' },
		{ label: 'EN', id: 'en' },
	];
	language: object | undefined = {};

	constructor(public location: Location, private readonly languageService: LanguageService) {
		this.languageService.autoResolveLanguage();
	}
}
