import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationRequestComponent } from './features/registration-request/registration-request.component';
import { StartComponent } from './layouts/start/start.component';
import { ResumeComponent } from './features/resume/resume.component';
import { FinishComponent } from './features/finish/finish.component';

const routes: Routes = [
	{
		path: 'start',
		component: StartComponent,
	},
	{
		path: 'registration/:option',
		component: RegistrationRequestComponent,
	},
	{
		path: 'resume',
		component: ResumeComponent,
	},
	{
		path: 'finish',
		component: FinishComponent,
	},
	{
		path: '',
		redirectTo: 'start',
		pathMatch: 'full',
	},
	{ path: '**', redirectTo: 'start' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
