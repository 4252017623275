import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import * as XLSX from 'xlsx';

@Injectable({
	providedIn: 'root',
})
export class XlsxService {
	constructor() {}

	private header: string[] = ['NOMBRE', 'EMAIL', 'DIRECCION', 'POBLACION', 'PROVINCIA', 'CODIGO POSTAL'];
	private row: string[] = ['Juan Pérez', 'juan.perez@email.com', 'Calle Falsa 123', 'Madrid', 'Madrid', '28080'];

	private fileData: any = null;
	private validationError: string = '';

	private fileDataSubject = new Subject<any>();

	generateTemplate() {
		let dataToWrite = [this.header, this.row];

		let ws = XLSX.utils.aoa_to_sheet(dataToWrite);

		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Plantilla');

		XLSX.writeFile(wb, 'plantilla_datos.xlsx');
	}

	readFile(file: any) {
		const reader = new FileReader();
		reader.onload = () => {
			const binaryString = reader.result as string;
			const workbook = XLSX.read(binaryString, { type: 'binary' });

			// Obtener la primera hoja del libro
			const sheet = workbook.Sheets[workbook.SheetNames[0]];

			// Convertir los datos de la hoja en un formato JSON
			let data: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });

			// Validar si los campos son correctos
			const validationError = this.validateHeaders(data[0]);
			if (validationError) {
				this.fileDataSubject.error(validationError);
			} else {
				data.shift(); //se eliminan las cabeceras
				let orderData = data.map((row: any) => {
					return {
						clickValue: '',
						cells: [
							{
								label: row[0],
							},
							{
								label: row[1],
							},
							{
								label: row[2],
							},
							{
								label: row[3],
							},
							{
								label: row[4],
							},
							{
								label: row[5],
							},
							{
								align: 'right',
								label: '',
								buttonIcon: {
									id: 'button2dropdown',
									icon: 'icon-more',
									menu: [
										{
											label: 'Editar',
											icon: 'icon-edit',
											id: 'option0',
										},
										{
											label: 'Eliminar',
											style: 'red',
											icon: 'icon-trash',
											id: 'option1',
											divider: true,
										},
									],
								},
							},
						],
					};
				});
				this.fileDataSubject.next(orderData);
			}
		};

		reader.readAsBinaryString(file);
	}

	getFileData(): Observable<any> {
		return this.fileDataSubject.asObservable();
	}

	validateHeaders(headers: string[]): string | null {
		const requiredHeaders = ['NOMBRE', 'EMAIL', 'DIRECCION', 'POBLACION', 'PROVINCIA', 'CODIGO POSTAL'];

		// Comprobar si los encabezados coinciden con los requeridos
		for (let i = 0; i < requiredHeaders.length; i++) {
			if (headers[i] !== requiredHeaders[i]) {
				return `Faltan o están desordenados los campos: ${requiredHeaders[i]}`;
			}
		}

		return null; // Si todo está bien, retornar null (sin error)
	}
}
