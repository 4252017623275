<tec-confirmation-layout
	title="¡Muchas gracias por cumplimentar el formulario!"
	[subtitle]="subtitle"
>
	<ng-container>
		<tec-tile style="white-space: pre-line" type="default" title="Firma manuscrita" icon="icon-sign">
			<div class="tile__body__column">
				<div class="grid grid-i1">
					<div class="info-group">
						<ul>
							<li>Te enviaremos un código QR que deberás escanear para poder firmar desde tu móvil.</li>
							<li>Para ello, necesitamos que aceptes las dos condiciones siguientes para firmar el contrato con Pensumo.</li>
						</ul>
					</div>
					<div class="info-group">
						<br>
						<span
							>El empleo de este método de firma implica que en Pensumo, Pensión por Consumo, S.L. trataremos tus datos
							personales.</span
						>
						<span
							>Autorizo a Pensumo, Pensión por Consumo, S.L. a realizar el tratamiento de mis datos personales, con la
							finalidad de..</span
						>
						<tec-switch (valueChange)="valueChange($event, 'switchAutorizacion')"></tec-switch>
					</div>
				</div>
			</div>
		</tec-tile>
		<tec-tile
			*ngIf="!switchAutorizacion"
			style="white-space: pre-line"
			type="default"
			title="Firma por código SMS"
			icon="icon-mail"
		>
			<div class="tile__body__column">
				<div class="grid grid-i1">
					<div class="info-group">
						<ul>
							<li>
								Te enviaremos un código OTP por sms para que puedas firmar de forma segura. Para ello, necesitamos que confirmes
								tu número de teléfono.
							</li>
							<li>
								El empleo de este método de firma implica que en Pensumo, Pensión por Consumo, S.L., trataremos tus datos
								personales.
							</li>
						</ul>
					</div>
					<div class="info-group">
						<b
							>Teléfono *<tec-tooltip
								label="Número de teléfono al que quieres que te enviemos el OTP para firmar el contrato"
								position="default"
							>
								<tec-icon name="icon-info"></tec-icon> </tec-tooltip
						></b>
					</div>
					<div class="grid grid-e4">
						<tec-input placeholder="+34 912 345 678" [formControl]="telefono"></tec-input>
					</div>
					<div class="grid grid-e5" *ngIf="!phoneValid">
						<tec-button
							[label]="'Confirmar teléfono'"
							[type]="'default'"
							(clickEvent)="clickEvent($event)"
							[disabled]="!form.valid"
						></tec-button>
					</div>
				</div>
			</div>
		</tec-tile>
	</ng-container>
	<tec-modal type="confirmation" [isVisible]="showModal" buttonPrimaryLabel="OK" (closeEvent)="closeModal($event)">
		<ng-container body>
			<tec-tag-icon [size]="'default'" [icon]="'icon-check'"></tec-tag-icon>
			<div class="body__text">
				<h4>Solicitud de alta enviada correctamente</h4>
				<span *ngIf="!!switchAutorizacion; else noAuthorized">
					En los próximos días te enviaremos el contrato con Pensumo para que lo revises y firmes. Recuerda que, al haber
					seleccionado el método de firma manuscrita, en Pensumo, trataremos tus datos...
				</span>
				<ng-template #noAuthorized>
					<span>En los próximos días te enviaremos el contrato con Pensumo para que lo revises y firmes</span>
				</ng-template>
			</div>
		</ng-container>
	</tec-modal>
	<tec-footer-actions>
		<tec-button
			[label]="step === 1 ? 'Volver atrás' : buttonLabel"
			[type]="'secondary'"
			(click)="buttonAction(step === 1 ? 1 : step - 1)"
		></tec-button>
		<tec-button
			[label]="'Enviar solicitud'"
			[type]="'default'"
			(click)="sendInformationEmail()"
			[disabled]="enableSendInformation"
		></tec-button>
	</tec-footer-actions>
</tec-confirmation-layout>
