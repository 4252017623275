<tec-base>
	<tec-topnav
		*ngIf="location.path().includes('registration') || location.path().includes('resume') || location.path().includes('finish')"
		[img]="'assets/images/pensumo-logo.svg'"
	>
		<tec-link [href]="'https://cdn.ibercaja.net/pensumo/comercios/informacion-comercios_v1.pdf'" target="_blank">Sobre Pensumo</tec-link>
		<tec-link [href]="'https://cdn.ibercaja.net/pensumo/comercios/faqs-comercios_v1.pdf'" target="_blank">FAQ's</tec-link>
		<tec-link [href]="'mailto:soportecomercios@pensumo.es'">Ayuda</tec-link>
	</tec-topnav>
	<router-outlet></router-outlet>
</tec-base>
