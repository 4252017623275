import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Form } from 'src/app/core/interfaces/form.interface';


@Injectable({
  providedIn: 'root'
})
export class FormService {

  private formSubject = new BehaviorSubject<Form>({ shops: [] });
	form$: Observable<Form> = this.formSubject.asObservable();

	setForm(data: Form) {
		this.formSubject.next({ ...this.formSubject.value, ...data });
	}

	getForm() {
		return this.formSubject.value;
	}

	resetForm() {
		this.formSubject.next({	});
	}
}
