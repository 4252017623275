import { NgModule } from '@angular/core';

import { DesignModule } from './design.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [],
	imports: [DesignModule, TranslateModule],
	exports: [DesignModule, TranslateModule],
	providers: [],
	bootstrap: [],
})
export class SharedModule {}
