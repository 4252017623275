import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	private readonly defaultLanguage = 'es';
	private readonly allowedLanguages = ['es', 'en'];
	private storageKey = 'lang';

	constructor(private translateService: TranslateService) {}

	public getLanguage(): string {
		const stored = localStorage.getItem(this.storageKey);
		if (!stored || !this.allowedLanguages.includes(stored)) {
			if (this.allowedLanguages.includes(navigator.language)) {
				return navigator.language;
			}
			return this.defaultLanguage;
		}
		return stored;
	}

	public setLanguage(lang: string): void {
		if(this.translateService) this.translateService.setDefaultLang('es');
		if(this.translateService) this.translateService.use(lang);
	}

	public autoResolveLanguage(): void {
		this.setLanguage('es');
	}
}
