import { FormService } from 'src/app/features/services/form.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function refillDatosForm(formService: FormService) {
	let datos = formService.getForm();
	return {
		pais: datos.pais,
		nombreComercio: datos.nombreComercio,
		web: datos.web,
		sector: datos.sector,
		actividad: datos.actividad,
		hosteleriaAdherido: datos.hosteleriaAdherido,
		volvemosAdherido: datos.volvemosAdherido,
		// hosteleriaAdherido: datos.hosteleriaAdherido !== '' ? true : false,
		// volvemosAdherido: datos.volvemosAdherido !== '' ? true : false,
		nifSociedad: datos.nifSociedad,
		razonSocSociedad: datos.razonSocAutonomo,
		emailSociedad: datos.emailSociedad,
		representacionSociedad: datos.representacionSociedad,
		inscripcionSociedad: datos.inscripcionSociedad,
		nombreRepresentante: datos.nombreRepresentante,
		dniRepresentante: datos.dniRepresentante,
		emailRepresentante: datos.emailRepresentante,
		telefonoProfesionalRepresentante: datos.telefonoProfesionalRepresentante,
		escritura: datos.escritura,
		nombreDeudor: datos.nombreDeudor,
		direccionDeudor: datos.direccionDeudor,
		codigoPostalDeudor: datos.codigoPostalDeudor,
		poblacionDeudor: datos.poblacionDeudor,
		provinciaDeudor: datos.provinciaDeudor,
		paisDeudor: datos.paisDeudor,
		SWIFT: datos.SWIFT,
		IBAN: datos.IBAN,
		localidadFirma: datos.localidadFirma,
		logotipo: datos.logotipo,
	};
}

export function refillDatosAutonomoForm(formService: FormService) {
	let datos = formService.getForm();
	return {
		pais: datos.pais,
		nombreComercio: datos.nombreComercio,
		web: datos.web,
		sector: datos.sector,
		actividad: datos.actividad,
		hosteleriaAdherido: datos.hosteleriaAdherido,
		volvemosAdherido: datos.volvemosAdherido,
		// hosteleriaAdherido: datos.hosteleriaAdherido !== '' ? true : false,
		// volvemosAdherido: datos.volvemosAdherido !== '' ? true : false,
		nombreAutonomo: datos.nombreAutonomo,
		dniAutonomo: datos.dniAutonomo,
		razonSocAutonomo: datos.razonSocAutonomo,
		emailProfesional: datos.emailProfesional,
		telefonoProfesional: datos.telefonoProfesional,
		direccionFiscal: datos.direccionFiscal,
		nombreDeudor: datos.nombreDeudor,
		direccionDeudor: datos.direccionDeudor,
		codigoPostalDeudor: datos.codigoPostalDeudor,
		poblacionDeudor: datos.poblacionDeudor,
		provinciaDeudor: datos.provinciaDeudor,
		paisDeudor: datos.paisDeudor,
		SWIFT: datos.SWIFT,
		IBAN: datos.IBAN,
		localidadFirma: datos.localidadFirma,
	};
}

export function sectores() {
	return [
		{ id: 1, label: 'Moda, calzado y complementos' },
		{ id: 2, label: 'Electrónica y tecnología' },
		{ id: 3, label: 'Hogar y decoración' },
		{ id: 4, label: 'Automoción' },
		{ id: 5, label: 'Alimentación y bebidas' },
		{ id: 6, label: 'Salud, bienestar y cuidado personal' },
		{ id: 7, label: 'Restauración' },
		{ id: 8, label: 'Turismo, viajes y alojamiento' },
		{ id: 9, label: 'Ocio y entretenimiento' },
		{ id: 10, label: 'Arte y cultura' },
		{ id: 11, label: 'Deportes y fitness' },
		{ id: 12, label: 'Educación' },
		{ id: 13, label: 'Infantil' },
		{ id: 14, label: 'Combustibles y energía' },
		{ id: 15, label: 'Suministros' },
		{ id: 16, label: 'Mascotas' },
		{ id: 17, label: 'Grandes superficies' },
		{ id: 18, label: 'Otros' },
	];
}

export function representations() {
	return [
		{ id: 'admin_unico', label: 'Administrador único' },
		{ id: 'conse_delegado', label: 'Consejero delegado' },
		{ id: 'admin_solidario', label: 'Administrador solidario' },
		{ id: 'apoderado', label: 'Apoderado' },
		{ id: 'Mancomunado', label: 'Mancomunado' },
	];
}

export function aportaciones() {
	return [
		{ id: 'porcentaje_compra', label: 'Porcentaje por cada compra' },
		{
			id: 'porcentaje_compra_minimo',
			label: 'Porcentaje por cada compra, siempre que el cliente supere un ticket mínimo',
		},
		{ id: 'porcentaje_compra_maximo', label: 'Porcentaje por cada compra, hasta un importe máximo que definas' },
	];
}



export function dniValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dni = control.value;

    // Si el campo está vacío, no valida (esto lo manejarás con required)
    if (!dni) {
      return null;
    }

    // Expresión regular para validar el formato
    const dniRegex = /^\d{8}[A-Za-z]$/;
    if (!dniRegex.test(dni)) {
      return { invalidDNI: 'El DNI debe tener 8 números seguidos de una letra' };
    }

    // Separar números y letra
    const number = parseInt(dni.slice(0, -1), 10);
    const letter = dni.slice(-1).toUpperCase();

    // Letras válidas
    const validLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';

    // Calcular la letra esperada
    const expectedLetter = validLetters[number % 23];

    // Comparar letra
    if (letter !== expectedLetter) {
      return { invalidDNI: 'La letra del DNI es incorrecta' };
    }

    // Si todo es válido
    return null;
  };
}

export function phoneValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
	  const phone = control.value;
  
	  // Si el campo está vacío, no valida (esto lo manejarás con required)
	  if (!phone) {
		return null;
	  }
  
	  // Expresión regular para validar teléfonos españoles
	  const phoneRegex = /^(\+34|0034)?[6789]\d{8}$/;
  
	  // Verificar si el número cumple con el formato
	  const isValid = phoneRegex.test(phone);
  
	  return isValid ? null : { invalidPhone: 'El número de teléfono no es válido' };
	};
  }

  export function nifSociedadValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
	  const nif = control.value;
  
	  // Si el campo está vacío, no valida (esto lo manejarás con required)
	  if (!nif) {
		return null;
	  }
  
	  // Expresión regular para validar el formato básico
	  const nifRegex = /^[A-Z]\d{7}[A-Z0-9]$/;
	  if (!nifRegex.test(nif)) {
		return { invalidNIF: 'Formato de NIF no válido' };
	  }
  
	  // Obtener letra inicial, los 7 números y el carácter de control
	  const letraInicial = nif[0];
	  const numeros = nif.slice(1, 8);
	  const caracterControl = nif[8];
  
	  // Letras válidas para sociedades
	  const letrasValidas = 'ABCDEFGHJNPQRSUVW';
  
	  // Verificar que la letra inicial esté en el rango válido
	  if (!letrasValidas.includes(letraInicial)) {
		return { invalidNIF: 'La letra inicial del NIF no es válida para una sociedad' };
	  }
  
	  // Calcular el carácter de control esperado
	  const sumaImpares = numeros
		.split('')
		.map((d: any, i: any) => (i % 2 === 0 ? parseInt(d, 10) * 2 : 0))
		.map((v: any) => (v > 9 ? v - 9 : v)) // Sumar dígitos si el resultado es mayor a 9
		.reduce((acc: any, cur: any) => acc + cur, 0);
  
	  const sumaPares = numeros
		.split('')
		.map((d: any, i: any) => (i % 2 !== 0 ? parseInt(d, 10) : 0))
		.reduce((acc: any, cur: any) => acc + cur, 0);
  
	  const sumaTotal = sumaImpares + sumaPares;
	  const resto = sumaTotal % 10;
	  const digitoControl = resto === 0 ? 0 : 10 - resto;
  
	  // Tabla de letras de control
	  const letrasControl = 'JABCDEFGHI';
  
	  const controlEsperado =
		letraInicial === 'N' || letraInicial === 'P' || letraInicial === 'Q' || letraInicial === 'R' || letraInicial === 'S' || letraInicial === 'W'
		  ? letrasControl[digitoControl] // Letra como carácter de control
		  : digitoControl.toString(); // Número como carácter de control
  
	  // Comparar el carácter de control esperado con el real
	  if (caracterControl !== controlEsperado) {
		return { invalidNIF: 'El carácter de control del NIF no es válido' };
	  }
  
	  // Si todo es válido
	  return null;
	};
  }