import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistrationRequestComponent } from './features/registration-request/registration-request.component';
import { StartComponent } from './layouts/start/start.component';
import { SharedModule } from './shared/shared.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { forkJoin, map, Observable } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { ResumeComponent } from './features/resume/resume.component';
import { FinishComponent } from './features/finish/finish.component';

class MultiHttpLoader implements TranslateLoader {
	constructor(private http: HttpClient, public resources: string[]) {}

	getTranslation(lang: string): Observable<any> {
		const requests = this.resources.map((path) => this.http.get(`${path}${lang}.json`));
		return forkJoin(requests).pipe(map((response) => {
			return Object.assign({}, ...response)
		}));
	}
}

export function HttpLoaderFactory(http: HttpClient) {
	return new MultiHttpLoader(http, [
		'./assets/i18n/',
		// '/i18n/'
	]);
}

@NgModule({
	declarations: [AppComponent, StartComponent, RegistrationRequestComponent, ResumeComponent, FinishComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		SharedModule,
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
