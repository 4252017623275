import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormService } from '../services/form.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-resume',
	templateUrl: './resume.component.html',
	styleUrls: ['./resume.component.scss'],
})
export class ResumeComponent implements OnInit {
	step: number = 1;
	buttonLabel: string = 'Volver';
	visibleItemsCount = 5;

	constructor(private router: Router, public formService: FormService, private location: Location) {}

	ngOnInit(): void {}

	buttonAction(option: string) {
		// this.step = option;
		if (option === 'BACK') {
			this.formService.setForm({ tab: 3 });
			this.router.navigate([
				'/registration/',
				this.formService.getForm().channel === 'CANAL ONLINE'
					? '0'
					: this.formService.getForm().channel === 'CANAL FISICO'
					? '1'
					: '2',
			]);
		}

		if (option === 'CONTINUE') {
			this.router.navigate(['finish']);
		}
		// if (this.step === 2) this.router.navigate(['finish']);
		// if (option === 'BACK') {
		// 	if (this.step === 1) this.formService.resetForm(), this.router.navigate(['/']);
		// }
		// if (Number(option) === 3) this.formService.setForm(this.step2Form.getRawValue());
	}

	edit(type: string, tab?: number) {
		if (tab) this.formService.setForm({ tab });
		this.router.navigate(['/registration/', type]);
	}

	showAll() {
		this.visibleItemsCount = this.visibleItemsCount === 5 ? this.formService.getForm().shops.length : 5;
	}
}
