import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Form } from 'src/app/core/interfaces/form.interface';

@Injectable({
	providedIn: 'root',
})
export class MailService {
	private apiKey = 'hdJcQ5Zs-3iZB-hoOj-Zeqg-o4uS6Gvw6QIT'; //dev
	// private apiKey = '9b582e27-4f01-4537-9a3e-5c3b5a64ff62'; //prod
	private headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'api-key': this.apiKey,
	});

	private base64Escritura: string = '';
	private base64Logo: string = '';

	constructor(private http: HttpClient) {}

	sendMailFormatted(dataForm: Form): Observable<any> {
		console.log(dataForm);
		// let initHtmlContent = `
    //   <!DOCTYPE html>
    //   <html lang="es">
    //   <head>
    //     <meta charset="UTF-8">
    //     <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //     <title>Alta de empresa en Pensumo</title>
    //     <style>
    //       table {
    //         border-collapse: collapse;
    //         width: 100%;
    //         margin-bottom: 15px;
    //       }
    //       th, td {
    //         border: 1px solid black;
    //         padding: 10px;
    //         text-align: center;
    //       }
    //       th {
    //         background-color: #f2f2f2;
    //       }
    //     </style>
    //   </head>
    //     <body>
    //       Hola Support,<br>
    //       A continuación se procede a mostrar la información recogida en el formulario de alta en Pensumo para su alta:
    //       <h1>Datos</h1>
    //       <h2>General</h2>
    //       <ul>
    //         <li><u><b>País: </b></u>${dataForm?.pais?.label}</li>
    //         <li><u><b>Nombre del comercio: </b></u>${dataForm.nombreComercio}</li>
    //         <li><u><b>Página web: </b></u>${dataForm.web}</li>
    //         <li><u><b>Sector al que pertenece: </b></u>${dataForm?.sector?.label}</li>
    //         <li><u><b>Descripción de la actividad: </b></u>${dataForm.actividad}</li>
    //       </ul>
    //       <h2>Comercio adherido</h2>
    //       <ul>
    //         <li><b><u>Hostelería de España (o asociación dependiente de esta): </b></u>${dataForm.hosteleriaAdherido !== '' ? 'SI' : 'NO'}</li>
    //         <li><b><u>VOLVEREMOS: </b></u>${dataForm.volvemosAdherido !== '' ? 'SI' : 'NO'}</li>
    //       </ul>
    // `;

		// let autonomoHtmlContent = `
    //       <h2>Detalles del autónomo</h2>
    //       <ul>
    //         <li><b><u>Nombre y apellidos: </b></u>${dataForm.nombreAutonomo}</li>
    //         <li><b><u>DNI: </b></u>${dataForm.dniAutonomo}</li>
    //         <li><b><u>Razón social: </b></u>${dataForm.razonSocAutonomo}</li>
    //         <li><b><u>Email profesional de contacto: </b></u>${dataForm.emailProfesional}</li>
    //         <li><b><u>Teléfono profesional de contacto: </b></u>${dataForm.telefonoProfesional}</li>
    //         <li><b><u>Dirección fiscal de facturación: </b></u>${dataForm.direccionFiscal}</li>
    //       </ul>
    // `;

		// let sociedadHtmlContent = `
    //       <h2>Detalles de la sociedad</h2>
    //       <ul>
    //         <li><u><b>NIF: </b></u>${dataForm.nifSociedad}</li>
    //         <li><u><b>Razón social: </b></u>${dataForm.razonSocSociedad}</li>
    //         <li><u><b>Email de notificaciones: </b></u>${dataForm.emailSociedad}</li>
    //         <li><u><b>Forma de representación de la sociedad: </b></u>${dataForm?.representacionSociedad?.label}</li>
    //         <li><u><b>Inscripción de la Seguridad Social en el Registro Mercantil: </b></u>${dataForm.inscripcionSociedad}</li>
    //       </ul>
    //       <h2>Representante</h2>
    //       <ul>
    //         <li><u><b>Nombre y apellidos: </b></u>${dataForm.nombreRepresentante}</li>
    //         <li><u><b>DNI: </b></u>${dataForm.dniRepresentante}</li>
    //         <li><u><b>Email profesional de contacto: </b></u>${dataForm.emailRepresentante}</li>
    //         <li><u><b>Teléfono profesional de contacto: </b></u>${dataForm.telefonoProfesionalRepresentante}</li>
    //       </ul>
    //       <h2>Escritura</h2>
    //       <ul>
    //         <li><u><b>Escritura: </b></u>Se adjunta a este correo</li>
    //       </ul>
    // `;

		// let mancomunados = '';
		// if (dataForm.mancomunados && dataForm.mancomunados.length > 0) {
		// 	dataForm.mancomunados.forEach((mancomunado) => {
		// 		mancomunados += `
    //         <ul>    
    //           <li><u><b>Nombre y apellidos: </b></u>${mancomunado.nombre}</li>
    //           <li><u><b>DNI: </b></u>${mancomunado.dni}</li>
    //           <li><u><b>Email: </b></u>${mancomunado.email}</li>
    //           <li><u><b>Teléfono: </b></u>${mancomunado.telefonoMancomunado}</li>
    //         </ul>
    //       ${dataForm.mancomunados && dataForm.mancomunados.length > 1 ? '<hr><br>' : ''}`;
		// 	});
		// }

		// let mancomunadosHtmlContent = `
    //       <h2>Mancomunados</h2>
    //       ${mancomunados}
    // `;

		// let mandatoSepaHtmlContent = `
    //       <h2>Mandato SEPA B2B</h2>
    //       <ul>
    //         <li><u><b>Nombre del deudor: </b></u>${dataForm.nombreDeudor}</li>
    //         <li><u><b>Dirección del deudor: </b></u>${dataForm.direccionDeudor}</li>
    //         <li><u><b>Código postal: </b></u>${dataForm.codigoPostalDeudor}</li>
    //         <li><u><b>Población: </b></u>${dataForm.poblacionDeudor}</li>
    //         <li><u><b>Provincia: </b></u>${dataForm.provinciaDeudor}</li>
    //         <li><u><b>País del deudor: </b></u>${dataForm.paisDeudor}</li>
    //         <li><u><b>SWIFT BIC: </b></u>${dataForm.SWIFT}</li>
    //         <li><u><b>IBAN: </b></u>${dataForm.IBAN}</li>
    //         <li><u><b>Localidad de la firma: </b></u>${dataForm.localidadFirma}</li>
    //       </ul>
    // `;

		// let logotipoHtmlContent = `
    //         <h2>Logotipo</h2>
    //         <ul>
    //           <li><u><b>Logotipo: </b></u>Se adjunta a este correo</li>
    //         </ul>
    // `;

		// let promocionHtmlContent = `
    //         <hr>
    //         <h1>Promoción</h1>        
    //         <h2>Política de devoluciones</h2>
    //         <ul>
    //           <li><u><b>¿Se permite devoluciones?: </b></u>${dataForm.plazoDevolucion && dataForm.plazoDevolucion !== '' ? 'SI' : 'NO'}</li>
    //           <li><u><b>Plazo de devolución: </b></u>${dataForm.plazoDevolucion && dataForm.plazoDevolucion !== '' ? dataForm.plazoDevolucion : '-'}</li>
    //         </ul>
    //         <h2>Aportación</h2>
    //         <ul>
    //           <li><u><b>Tipo de aportación: </b></u>${dataForm.tipoAportacion?.label}</li>
    //           <li><u><b>Porcentaje de aportación por compra (%): </b></u>${dataForm.aportacionCompra}</li>
    //            ${dataForm.tipoAportacion?.id === 'porcentaje_compra'	? '' : dataForm.tipoAportacion?.id === 'porcentaje_compra_minimo'
		// 																		? `<li><u><b>Ticket mínimo de la compra: </b></u>${dataForm.aportacionCompraMinimoTicket}</li>`
		// 																		: `<li><u><b>Máximo de aportación (€): </b></u>${dataForm.aportacionCompraMaximoTicket}</li>`
		// 					  }
    //         </ul>
    // `;

		// let tiendaOnlineHtmlContent = `
    //         <hr>        
    //         <h1>Tiendas</h1>
    //         <h2>Tienda online</h2>
    //         <ul>
    //             <li><u><b>URL de tu página web de venta: </b></u>${dataForm.urlWebVenta}</li>
    //             ${dataForm.tpvOnline && dataForm.tpvOnline !== ''
		// 									? `<li><u><b>MerchantID o nº de comercio del TPV online: </b></u>${dataForm.tpvOnline}</li>`
		// 									: ''
		// 						}
    //             <li><u><b>Email de contacto: </b></u>${dataForm.emailContactoWebVenta}</li>
    //             <li><u><b>Tipo de tienda: </b></u>Tienda online</li>
    //         </ul>
    // `;

		// let establecimientos = '';
		// if (dataForm.shops && dataForm.shops.length > 0) {
		// 	dataForm.shops.forEach((shop: any) => {
		// 		establecimientos += `
    //       <ul>
    //         <li><u><b>Nombre: </b></u>${shop.cells[0].label}</li>
    //         <li><u><b>Email: </b></u>${shop.cells[1].label}</li>
    //         <li><u><b>Dirección: </b></u>${shop.cells[2].label}</li>
    //         <li><u><b>Población: </b></u>${shop.cells[3].label}</li>
    //         <li><u><b>Provincia: </b></u>${shop.cells[4].label}</li>
    //         <li><u><b>Código postal: </b></u>${shop.cells[5].label}</li>
    //         <li><u><b>Tipo de tienda: </b></u>Tienda física</li>
    //       </ul>
    //       ${dataForm.shops && dataForm.shops.length > 1 ? '<hr><br>' : ''}`;
		// 	});
		// }

		// let establecimientosHtmlContent = `
    //         <hr>        
    //         <h1>Tiendas</h1>
    //         <h2>Establecimientos</h2>
    //         ${establecimientos}
    // `;

		// let finishHtmlContent = `
    //     <br><br><br><br>
    //     En caso de detectar algún problema con la información contenido en este correo, por favor, diríjase al administrador de la web del formulario.
    //     <br>Muchas gracias.
    //     </body>
    //   </html>
    // `;

		// let attachments: any = [];

		// if (dataForm.businessType === 'Sociedad') {
		// 	attachments.push(
		// 		{
		// 			filename: 'escritura.png',
		// 			base64_content: dataForm.escritura ? this.base64Escritura.split('base64,')[1] : '',
		// 			encoding: 'base64',
		// 		},
		// 		{
		// 			filename: 'logotipo.png',
		// 			base64_content: dataForm.logotipo ? this.base64Logo.split('base64,')[1] : '',
		// 			encoding: 'base64',
		// 		}
		// 	);
		// }
		let initHtmlContent = `
      <!DOCTYPE html>
      <html lang="es">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Alta de empresa en Pensumo</title>
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
            margin-bottom: 15px;
          }
          th, td {
            border: 1px solid black;
            padding: 10px;
            text-align: center;
          }
          th {
            background-color: #f2f2f2;
          }
        </style>
      </head>
        <body>
          Hola Support,<br>
          A continuación se procede a mostrar la información recogida en el formulario de alta en Pensumo para su alta:

          <h1>Datos</h1>
          <h2>General</h2>
           <table border='2'>
              <thead>
                <tr>
                  <th>País</th>
                  <th>Nombre del comercio</th>
                  <th>Página web</th>
                  <th>Sector al que pertenece</th>
                  <th>Descripción de la actividad</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm?.pais?.label}</td>
                  <td>${dataForm.nombreComercio}</td>
                  <td>${dataForm.web}</td>
                  <td>${dataForm?.sector?.label}</td>
                  <td>${dataForm.actividad}</td>
                </tr>
              </tbody>
            </table>
            <h2>Comercio adherido</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>Hostelería de España (o asociación dependiente de esta)</th>
                  <th>VOLVEREMOS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm.hosteleriaAdherido !== '' ? 'SI' : 'NO'}</td>
                  <td>${dataForm.volvemosAdherido !== '' ? 'SI' : 'NO'}</td>
                </tr>
              </tbody>
            </table>
    `;

		let autonomoHtmlContent = `
            <h2>Detalles del autónomo</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>Nombre y apellidos</th>
                  <th>DNI</th>
                  <th>Razón social</th>
                  <th>Email profesional de contacto</th>
                  <th>Teléfono profesional de contacto</th>
                  <th>Dirección fiscal de facturación</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm.nombreAutonomo}</td>
                  <td>${dataForm.dniAutonomo}</td>
                  <td>${dataForm.razonSocAutonomo}</td>
                  <td>${dataForm.emailProfesional}</td>
                  <td>${dataForm.telefonoProfesional}</td>
                  <td>${dataForm.direccionFiscal}</td>
                </tr>
              </tbody>
            </table>
    `;

		let sociedadHtmlContent = `
            <h2>Detalles de la sociedad</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>NIF</th>
                  <th>Razón social</th>
                  <th>Email de notificaciones</th>
                  <th>Forma de representación de la sociedad</th>
                  <th>Inscripción de la Seguridad Social en el Registro Mercantil</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm.nifSociedad}</td>
                  <td>${dataForm.razonSocSociedad}</td>
                  <td>${dataForm.emailSociedad}</td>
                  <td>${dataForm?.representacionSociedad?.label}</td>
                  <td>${dataForm.inscripcionSociedad}</td>
                </tr>
              </tbody>
            </table>
            <h2>Representante</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>Nombre y apellidos</th>
                  <th>DNI</th>
                  <th>Email profesional de contacto</th>
                  <th>Teléfono profesional de contacto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm.nombreRepresentante}</td>
                  <td>${dataForm.dniRepresentante}</td>
                  <td>${dataForm.emailRepresentante}</td>
                  <td>${dataForm.telefonoProfesionalRepresentante}</td>
                </tr>
              </tbody>
            </table>
            <h2>Escritura</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>Escritura</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Se adjunta a este correo</td>
                </tr>
              </tbody>
            </table>
    `;

		let mancomunados = '';
		if (dataForm.mancomunados && dataForm.mancomunados.length > 0) {
			dataForm.mancomunados.forEach((mancomunado) => {
				mancomunados += `
          <tr>
            <td>${mancomunado.nombre}</td>
            <td>${mancomunado.dni}</td>
            <td>${mancomunado.email}</td>
            <td>${mancomunado.telefonoMancomunado}</td>
          </tr>`;
			});
		}

		let mancomunadosHtmlContent = `
            <h2>Mancomunados</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>Nombre y apellidos</th>
                  <th>DNI</th>
                  <th>Email</th>
                  <th>Teléfono</th>
                </tr>
              </thead>
              <tbody>
                ${mancomunados}
              </tbody>
            </table>
    `;

		let mandatoSepaHtmlContent = `
            <h2>Mandato SEPA B2B</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>Nombre del deudor</th>
                  <th>Dirección del deudor</th>
                  <th>Código Postal</th>
                  <th>Población</th>
                  <th>Provincia</th>
                  <th>País del deudor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm.nombreDeudor}</td>
                  <td>${dataForm.direccionDeudor}</td>
                  <td>${dataForm.codigoPostalDeudor}</td>
                  <td>${dataForm.poblacionDeudor}</td>
                  <td>${dataForm.provinciaDeudor}</td>
                  <td>${dataForm.paisDeudor}</td>
                </tr>
              </tbody>
            </table>
            <br>
            <table>
              <thead>
                <tr>
                  <th>SWIFT BIC</th>
                  <th>IBAN</th>
                  <th>Localidad de la firma</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm.SWIFT}</td>
                  <td>${dataForm.IBAN}</td>
                  <td>${dataForm.localidadFirma}</td>
                </tr>
              </tbody>
            </table>
    `;

		let logotipoHtmlContent = `
            <h2>Logotipo</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>Logotipo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Se adjunta a este correo</td>
                </tr>
              </tbody>
            </table>
    `;

		let promocionHtmlContent = `
            <hr>
            <h1>Promoción</h1>        
            <h2>Política de devoluciones</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>¿Se permite devoluciones?</th>
                  <th>Plazo de devolución</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm.plazoDevolucion && dataForm.plazoDevolucion !== '' ? 'SI' : 'NO'}</td>
                  <td>${
																			dataForm.plazoDevolucion && dataForm.plazoDevolucion !== '' ? dataForm.plazoDevolucion : '-'
																		}</td>
                </tr>
              </tbody>
            </table>
            <h2>Aportación</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>Tipo de aportación</th>
                  <th>Porcentaje de aportación por compra (%)</th>
                  ${
																			dataForm.tipoAportacion?.id === 'porcentaje_compra'
																				? ''
																				: dataForm.tipoAportacion?.id === 'porcentaje_compra_minimo'
																				? '<th>Ticket mínimo de la compra</th>'
																				: '<th>Máximo de aportación (€)</th>'
																		}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm.tipoAportacion?.label}</td>
                  <td>${dataForm.aportacionCompra}</td>
                  ${
																			dataForm.tipoAportacion?.id === 'porcentaje_compra'
																				? ''
																				: dataForm.tipoAportacion?.id === 'porcentaje_compra_minimo'
																				? '<td>' + dataForm.aportacionCompraMinimoTicket + '</td>'
																				: '<td>' + dataForm.aportacionCompraMaximoTicket + '</td>'
																		}
                </tr> 
              </tbody>
            </table>
    `;

		let tiendaOnlineHtmlContent = `
            <hr>        
            <h1>Tiendas</h1>
            <h2>Tienda online</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>URL de tu página web de venta</th>
                  ${
																			dataForm.tpvOnline && dataForm.tpvOnline !== ''
																				? `<th>MerchantID o nº de comercio del TPV online</th>`
																				: ''
																		}
                  <th>Email de contacto</th>
                  <th>Tipo de tienda</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${dataForm.urlWebVenta}</td>
                  ${dataForm.tpvOnline && dataForm.tpvOnline !== '' ? `<td>${dataForm.tpvOnline}</td>` : ''}
                  <td>${dataForm.emailContactoWebVenta}</td>
                  <td>Tienda online</td>
                </tr>
              </tbody>
            </table>
    `;

		let establecimientos = '';
		if (dataForm.shops && dataForm.shops.length > 0) {
			dataForm.shops.forEach((shop: any) => {
				establecimientos += `
          <tr>
            <td>${shop.cells[0].label}</td>
            <td>${shop.cells[1].label}</td>
            <td>${shop.cells[2].label}</td>
            <td>${shop.cells[3].label}</td>
            <td>${shop.cells[4].label}</td>
            <td>${shop.cells[5].label}</td>
            <td>Tienda física</td>
          </tr>`;
			});
		}

		let establecimientosHtmlContent = `
            <hr>        
            <h1>Tiendas</h1>
            <h2>Establecimientos</h2>
            <table border='2'>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Dirección</th>
                  <th>Población</th>
                  <th>Provincia</th>
                  <th>Código postal</th>
                  <th>Tipo de tienda</th>
                </tr>
              </thead>
              <tbody>
              ${establecimientos}
              </tbody>
            </table>
    `;

		let finishHtmlContent = `
        <br><br><br><br>
        En caso de detectar algún problema con la información contenido en este correo, por favor, diríjase al administrador de la web del formulario.
        <br>Muchas gracias.
        </body>
      </html>
    `;

		let attachments: any = [];

		if (dataForm.businessType === 'Sociedad') {
			attachments.push(
				{
					filename: 'escritura.png',
					base64_content: dataForm.escritura ? this.base64Escritura.split('base64,')[1] : '',
					encoding: 'base64',
				},
				{
					filename: 'logotipo.png',
					base64_content: dataForm.logotipo ? this.base64Logo.split('base64,')[1] : '',
					encoding: 'base64',
				}
			);
		}

		return this.http.post(
			'/email',
			{
				subject: 'Prueba correo pensumo',
				from: 'noreply@dexga.com',
				fromName: 'Dexga Technologies',
				to: [
					{
						email: 'soporte-pensumo@tecalis.com',
						name: 'Support',
					},
					{
						email: 'josemaria.perez@tecalis.com',
						name: 'José María',
					},
				],
				attachments,
				html_content:
					initHtmlContent + // datos generales comunes
					(dataForm.businessType === 'Autonomo' ? autonomoHtmlContent : sociedadHtmlContent) + // datos concretos autonomo o sociedad
					(dataForm.mancomunados && dataForm.mancomunados.length > 0 ? mancomunadosHtmlContent : '') + // mancomunados sociedad (si hay)
					mandatoSepaHtmlContent + // mandato sepa, comun a ambos
					(dataForm.businessType !== 'Autonomo' ? logotipoHtmlContent : '') + // logotipo sociedad
					promocionHtmlContent + // step2: promociones
					(dataForm.channel === 'CANAL ONLINE' ? tiendaOnlineHtmlContent : dataForm.channel === 'CANAL ONLINE Y FISICO' ? tiendaOnlineHtmlContent + establecimientosHtmlContent : establecimientosHtmlContent) + //step3: tiendas
					finishHtmlContent, // cierre html
			},
			{ headers: this.headers }
		);
	}

	convertToBase64(file: File, type: string): void {
		const reader = new FileReader();

		reader.onload = () => {
			if (type === 'escritura') this.base64Escritura = reader.result as string;
			if (type === 'logo') this.base64Logo = reader.result as string;
		};

		reader.readAsDataURL(file);
	}
}
