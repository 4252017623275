import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormService } from 'src/app/features/services/form.service';

@Component({
	selector: 'app-start',
	templateUrl: './start.component.html',
	styleUrls: ['./start.component.scss'],
})
export class StartComponent {
	step = 1;
	isSelectedOption = [false, false, false];
	isSelectedOptionBoth = [false, false];
	option!: number;
	optionBoth!: number;
	title: string = '¿Qué tipo de comercio eres?';
	bothTitle: string = '¿Con que canal te quieres adherir a Pensumo?';
	subTitle: string =
		'<br>Solo se recompensarán con Pensumo aquellas compras realizadas a través de tus TPVs.</br> \nPor ejemplo: no aplicaremos la presente promoción a las ventas que se efectúen a través de otras plataformas o comercios (Amazon, Uber Eats, Glovo...).';
	bothSubTitle: string =
		'Si tiene alguna duda sobre el tipo de promoción a aplicar con Pensumo, contáctenos en soportecomercios@pensumo.es';
	disabled: boolean = true;
	bothSelected: boolean = false;

	constructor(private readonly router: Router, private formService: FormService, private http: HttpClient) {}

	selectOption(option: number, type: string): void {
		this.option = option;
		this.isSelectedOption[option] = !this.isSelectedOption[option];
		this.isSelectedOption.filter((boolean, index) =>
			index !== option ? (this.isSelectedOption[index] = this.disabled = false) : boolean
		);

		if (this.isSelectedOption.every((el) => el === false)) this.disabled = true;
		if (type) this.formService.setForm({ channel: type });
	}

	selectOptionBoth(option: number, type: string): void {
		this.optionBoth = option;
		this.isSelectedOptionBoth[option] = !this.isSelectedOptionBoth[option];
		this.isSelectedOptionBoth.filter((boolean, index) =>
			index !== option ? (this.isSelectedOptionBoth[index] = this.disabled = false) : boolean
		);

		if (this.isSelectedOptionBoth.every((el) => el === false)) this.disabled = true;
		if (type) this.formService.setForm({ channelBoth: type });
	}

	navigate(both?: string): void {
		if (this.option === 2 && !this.bothSelected) (this.bothSelected = true), (this.disabled = true);
		if ((!this.bothSelected && this.isSelectedOption.find((boolean) => boolean)) || both)
			this.router.navigate(['/registration', this.option]);
	}
}
